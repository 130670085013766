import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../Redux - Saga/store/store";

import {
  LoginActions,
  LoginState,
  OtpVerifyState,
  OtpVerifyActions,
  LoginOtpSuccessPayload,
  LoginOtpFailurePayload,
} from "../../constants/model";
import { toast } from "react-toastify";

const initialState: LoginState | OtpVerifyState = {
  loading: false,
  isAuthenticated: false,
  data: null,
  error: null,
  otpSent: false,
};

export const LoginReducer: any = createSlice({
  name: "login",
  initialState,
  reducers: {
    loginRequest(state: any, action: PayloadAction<any>): void {
      localStorage.clear();
      state.loading = true;
      state.isAuthenticated = false;
      state.error = null;
    },
    loginSuccess(state: any, action: PayloadAction<LoginOtpSuccessPayload>) {
      state.loading = false;
      state.data = action.payload;
      state.isAuthenticated = false;
      state.otpSent = true;
      state.error = null;
    },
    loginFailed(state: any, action: PayloadAction<LoginOtpFailurePayload>) {
      state.loading = false;
      state.isAuthenticated = false;
      state.data = action.payload;
      state.otpSent = true;
      state.error = action.payload;
    },

    otpVerifyRequest(state: any, action: PayloadAction<any>): void {
      state.loading = true;
      state.isAuthenticated = false;
      state.error = null;
    },
    otpVerifySuccess(
      state: any,
      action: PayloadAction<LoginOtpSuccessPayload>
    ) {
      state.loading = false;
      state.isAuthenticated = true;
      state.data = action.payload;
      state.otpSent = false;
      toast.success("Login Successful!", {
        position: toast.POSITION.TOP_CENTER,
      });
      state.error = null;
    },
    otpVerifyFailed(state: any, action: PayloadAction<LoginOtpFailurePayload>) {
      state.loading = false;
      state.isAuthenticated = false;
      state.data = action.payload;
      state.otpSent = false;
      state.error = action.payload;
    },

    logOutRequest(state: any, action: PayloadAction<any>): void {
      state.loading = true;
      state.isAuthenticated = false;
      state.error = null;
    },
    logOutSuccess(state: any, action: PayloadAction<LoginOtpSuccessPayload>) {
      state.loading = false;
      state.isAuthenticated = false;
      state.data = action.payload;
      localStorage.clear();
      toast.success("Logout Successful!", {
        position: toast.POSITION.TOP_CENTER,
      });
    },
    logOutFailed(state: any, action: PayloadAction<LoginOtpFailurePayload>) {
      state.loading = false;
      state.data = action.payload;
    },

    logout(state: any) {
      state.isAuthenticated = false;
      state.loading = false;
      state.data = null;
      localStorage.clear();
      // toast.success("Logout Successful!", {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
    },
  },
});

export const {
  loginFailed,
  loginSuccess,
  loginRequest,
  logout,
  otpVerifyRequest,
  otpVerifySuccess,
  otpVerifyFailed,
  logOutRequest,
  logOutSuccess,
  logOutFailed,
} = LoginReducer.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value
export const loginState = (state: RootState) => state.login;
export default LoginReducer.reducer;

// export default (state = initialState, action: LoginActions| OtpVerifyActions|any ) => {

//     switch (action.type) {

//         case LOGIN_OTP_REQUEST:
//             return {
//                 ...state,
//                 pending: true,
//             };
//         case LOGIN_OTP_SUCCESS:
//             return {
//                 ...state,
//                 pending: false,
//                 data: action.payload,
//                 error: null,
//             };
//         case LOGIN_OTP_FAILURE:
//             return {
//                 ...state,
//                 pending: false,
//                 data: null,
//                 error: action.payload.error,
//             };

//             case USER_OTP_VERIFY_REQUEST:
//                 return {
//                     ...state,
//                     pending: true,
//                     varified: false,
//                 };
//             case USER_OTP_VERIFY_SUCCESS:
//                 console.log(action.payload)
//                 return {
//                     ...state,
//                     pending: false,
//                     data: action.payload,
//                     error: null,
//                     varified: true,
//                 };
//             case USER_OTP_VERIFY_FAILURE:
//                 return {
//                     ...state,
//                     pending: false,
//                     data: null,
//                     varified: false,
//                     error: action.payload.error,
//                 };

//             case USER_LOGOUT:
//                 return {
//                         ...state,
//                         pending: false,
//                         data: null,
//                         varified: false,

//                     };

//         default:
//             return {
//                 ...state,
//             };
//     }
// };
