import React from "react";
import skaterLoader from "../../assests/images/leader-1.gif";
const Loader = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <img
        src={skaterLoader}
        style={{
          //   width: "100vw",
          height: "35px",
          width: "35px",
        }}
        alt="loader"
      />
    </div>
  );
};

export default Loader;
