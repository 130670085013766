import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../Redux - Saga/store/store";

import {
  GET_RECENT_TRANSACTION_REQUEST,
  GET_RECENT_TRANSACTION_SUCCESS,
  GET_RECENT_TRANSACTION_FAILURE,
  GET_TRANSACTION_HISTORY_REQUEST,
  GET_TRANSACTION_HISTORY_SUCCESS,
  GET_TRANSACTION_HISTORY_FAILURE,
} from "../../constants/Types";

import {
  recentTransactionAction,
  RecentTransationState,
  transactionHistoryAction,
  TransationHistoryState,
} from "../../constants/model";
import {
  LoginActions,
  LoginState,
  OtpVerifyState,
  OtpVerifyActions,
  LoginOtpSuccessPayload,
  LoginOtpFailurePayload,
  recentTransactionSuccessPayload,
  recentTransactionFailurePayload,
} from "../../constants/model";

const initialState: RecentTransationState | TransationHistoryState = {
  loading: false,
  data: null,
  error: null,
};

export const transactionReducer: any = createSlice({
  name: "transaction",
  initialState,
  reducers: {
    recentTransactionRequest(state: any, action: PayloadAction<any>): void {
      state.loading = true;
    },
    recentTransactionSuccess(
      state: any,
      action: PayloadAction<recentTransactionSuccessPayload>
    ) {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    },
    recentTransactionFailed(
      state: any,
      action: PayloadAction<recentTransactionFailurePayload>
    ) {
      state.loading = false;
      state.data = action.payload;
    },

    transactionHistoryRequest(state: any, action: PayloadAction<any>): void {
      state.loading = true;
    },
    transactionHistorySuccess(
      state: any,
      action: PayloadAction<LoginOtpSuccessPayload>
    ) {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    },
    transactionHistoryFailed(
      state: any,
      action: PayloadAction<LoginOtpFailurePayload>
    ) {
      state.loading = false;
      state.error = action.payload;
    },

    logout(state: any) {
      state.isAuthenticated = false;
      state.loading = false;
      state.data = null;
      localStorage.clear();
    },
  },
});

export const {
  recentTransactionFailed,
  recentTransactionSuccess,
  recentTransactionRequest,
  logout,
  transactionHistoryRequest,
  transactionHistorySuccess,
  transactionHistoryFailed,
} = transactionReducer.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value
// export const loginState = (state: RootState) => state.transaction
export default transactionReducer.reducer;

// export default (state = initialState, action: recentTransactionAction| transactionHistoryAction ) => {
//     // console.log(action.payload);
//     switch (action.type) {

//         //recent transaction

//         case GET_RECENT_TRANSACTION_REQUEST:
//             return {
//                 ...state,
//                 loading: true
//             };
//         case GET_RECENT_TRANSACTION_SUCCESS:
//             return {
//                 ...state,
//                 loading: false,
//                 data: action.payload,
//                 error: null,
//             };
//         case  GET_RECENT_TRANSACTION_FAILURE:
//             return {
//                 ...state,
//                loading: false,
//                 data: null,
//                 error: action.payload.error,
//             };

//             //transaction history
//             case GET_TRANSACTION_HISTORY_REQUEST:
//                 return {
//                     ...state,
//                     loading: true,
//                 };
//             case GET_TRANSACTION_HISTORY_SUCCESS:
//                 return {
//                     ...state,
//                    loading: false,
//                     data: action.payload,
//                     error: null,
//                 };
//             case GET_TRANSACTION_HISTORY_FAILURE:
//                 return {
//                     ...state,
//                    loading: false,
//                     data: null,
//                     error: action.payload.error,
//                 };

//         default:
//             return {
//                 ...state,
//             };
//     }
// };
