import { combineReducers } from "@reduxjs/toolkit";
import FaqReducer from "./FaqReducer";
import GlobalSetting from "./GlobalSetting";
import LoginReducer from "./LoginReducer";
import RedeemReducer from "./RedeemReducer";
import SearchReducer from "./SearchReducer";
import TransactionReducer from "./TransactionReducer";

const rootReducer = combineReducers({
  login: LoginReducer,
  transaction: TransactionReducer,
  redeem: RedeemReducer,
  faq: FaqReducer,
  search: SearchReducer,
  globalSetting: GlobalSetting,
});

export default rootReducer;
