import { all, fork } from "redux-saga/effects";
import faqSaga from "./FaqSaga";

import loginSaga from "./LoginSaga";
import redeemSaga from "./RedeemSaga";
import searchSaga from "./SearchSaga";
import transactionSaga from "./TransactionSaga";
import GlobalSettingSaga from "./GlobalSettingSaga";

export function* rootSaga() {
  yield all([fork(loginSaga)]);
  yield all([fork(transactionSaga)]);
  yield all([fork(redeemSaga)]);
  yield all([fork(faqSaga)]);
  yield all([fork(searchSaga)]);
  yield all([fork(GlobalSettingSaga)]);
}
