import React, { useState } from "react";
//import { FaArrowCircleUp } from 'react-icons/fa';
import { FaAngleUp } from "react-icons/fa";

const ScrollButton = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 100) {
      setVisible(true);
    } else if (scrolled <= 100) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  window.addEventListener("scroll", toggleVisible);

  return (
    <div
      onClick={scrollToTop}
      style={{
        position: "fixed",
        justifyContent: "center",
        alignItems: "center",
        bottom: "20px",
        right: "0px",
        // height: "40px",
        // width: "40px",
        background: "#269adb",
        color: "#fff",
        // lineHeight: "25px",
        borderRadius: "50% 0 0 50%",
        cursor: "pointer",
        display: visible ? "flex" : "none",
        // fontSize: "20px",
        zIndex: "1111",
        padding: "12px",
        overflow: "hidden",
      }}
    >
      <FaAngleUp
        className="animate__animated animate__bounce"
        style={{ height: "25px", width: "25px" }}
      />
    </div>
  );
};

export default ScrollButton;
