import axiosClient from "./axiosClient";

const redeemApi = {
  sendRequestAmount(params: any): any {
    const url = "/api/customer/redeem-request";
    return axiosClient.post(url, {
      request_amount: params.request_amount,
    });
  },
};

export default redeemApi;
