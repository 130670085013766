import transactionAPI from "../../API/transactionApi";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  recentTransactionRequest,
  transactionHistoryRequest,
  recentTransactionFailed,
  recentTransactionSuccess,
  transactionHistoryFailed,
  transactionHistorySuccess,
} from "../reducers/TransactionReducer";
import { toast } from "react-toastify";
import { useAppDispatch } from "../../constants/hooks";
import { logout } from "../reducers/LoginReducer";

const fetchrecentTransaction = () => {
  return transactionAPI.getRecentTransaction();
};

function* getRecentTransactionSaga(): Generator<any> {
  try {
    const response: any = yield call(fetchrecentTransaction);
    yield put(
      recentTransactionSuccess({
        data: response,
      })
    );
  } catch (error: any) {
    toast.error(`${error?.message}`, {
      position: toast.POSITION.TOP_CENTER,
    });
    yield put(
      recentTransactionFailed({
        error: error?.message,
      })
    );
  }
}

const fetchTransactionHistory = () => {
  return transactionAPI.getTransactionHistory();
};

function* getTransactionHistorySaga(): Generator<any> {
  try {
    const response: any = yield call(fetchTransactionHistory);
    yield put(
      transactionHistorySuccess({
        data: response,
      })
    );
  } catch (error: any) {
    toast.error(`${error?.message}`, {
      position: toast.POSITION.TOP_CENTER,
    });
    yield put(
      transactionHistoryFailed({
        error: error?.message,
      })
    );
  }
}

function* transactionSaga() {
  yield all([takeLatest(recentTransactionRequest, getRecentTransactionSaga)]);
  yield all([takeLatest(transactionHistoryRequest, getTransactionHistorySaga)]);
}

export default transactionSaga;
