import { all, call, put, takeLatest } from "redux-saga/effects";
import globalSettingApi from "../../API/globalSettingApi";
import {
  settingFailed,
  settingRequest,
  settingSuccess,
} from "../reducers/GlobalSetting";

const requestSetting = () => {
  return globalSettingApi.getGlobalSetting();
};
function* sendRequestSetting(ph: any): Generator<any> {
  try {
    const response: any = yield call(requestSetting);
    yield put(
      settingSuccess({
        data: response,
      })
    );
  } catch (error: any) {
    yield put(
      settingFailed({
        error: error?.message,
      })
    );
  }
}

function* GlobalSettingSaga() {
  yield all([takeLatest(settingRequest, sendRequestSetting)]);
}

export default GlobalSettingSaga;
