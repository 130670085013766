import { createSlice, PayloadAction } from "@reduxjs/toolkit";
export interface search {
  loading: boolean;
  data: any;
  error: string | null;
}

const initialState: search = {
  loading: false,
  data: null,
  error: null,
};

export const SearchReducer: any = createSlice({
  name: "search",
  initialState,
  reducers: {
    searchRequest(state: any, action: PayloadAction<any>): void {
      state.loading = true;
    },
    searchSuccess(state: any, action: PayloadAction) {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    },
    searchFailed(state: any, action: PayloadAction) {
      state.loading = false;
      state.data = action.payload;
    },

    paginationRequest(state: any, action: PayloadAction<any>): void {
      state.loading = true;
    },
    paginationSuccess(state: any, action: PayloadAction) {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    },
    paginationFailed(state: any, action: PayloadAction) {
      state.loading = false;
      state.data = action.payload;
    },

    logout(state: any) {
      state.isAuthenticated = false;
      state.loading = false;
      state.data = null;
    },
  },
});

export const {
  searchRequest,
  searchSuccess,
  searchFailed,
  logout,
  paginationRequest,
  paginationSuccess,
  paginationFailed,
} = SearchReducer.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value
// export const loginState = (state: RootState) => state.transaction
export default SearchReducer.reducer;
