import axiosClient from "./axiosClient";

const transactionApi = {
  getRecentTransaction(): any {
    const url = `/api/customer/recent-transactions`;
    return axiosClient.post(url).catch((err) => {
      return err.code;
    });
  },

  getTransactionHistory(): any {
    const url = "/api/customer/transactions-history";
    return axiosClient.post(url).catch((err) => {
      return err.code;
    });
  },
};

export default transactionApi;
