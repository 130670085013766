import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

export interface globalSetting {
  loading: boolean;
  data1: any;
  error: string | null;
}

const initialState: globalSetting = {
  loading: false,
  data1: null,
  error: null,
};

export const GlobalSetting: any = createSlice({
  name: "globalSetting",
  initialState,
  reducers: {
    settingRequest(state: any, action: PayloadAction<any>): void {
      state.loading = true;
    },
    settingSuccess(state: any, action: PayloadAction) {
      state.loading = false;
      state.data1 = action.payload;
      state.error = null;
      // toast.success(`${action?.payload?.messsage}`, {
      //   position: toast.POSITION.TOP_CENTER,
      // });
    },
    settingFailed(state: any, action: PayloadAction) {
      state.loading = false;
      state.data1 = action.payload;
    },

    logout(state: any) {
      state.isAuthenticated = false;
      state.loading = false;
      state.data1 = null;
    },
  },
});

export const { settingRequest, settingSuccess, settingFailed, logout } =
  GlobalSetting.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value
// export const loginState = (state: RootState) => state.transaction
export default GlobalSetting.reducer;
