import axiosClient from "./axiosClient";

const searchApi = {
  sendRequest(params: any): any {
    const url = "/api/transaction-pagination";
    return axiosClient.post(url, {
      data: params.data,
    });
  },

  sendPaginationRequest(params: any): any {
    const url = `/api/transaction-pagination?limit=${params.limit}&page=${params.page}`;
    return axiosClient.post(url, {
      data: params.data,
    });
  },
};

export default searchApi;
