import { all, call, put, takeLatest } from "redux-saga/effects";
import redeemApi from "../../API/redeemApi";
import { toast } from "react-toastify";
import { loginSuccess } from "../reducers/LoginReducer";
import {
  redeemFailed,
  redeemRequest,
  redeemSuccess,
} from "../reducers/RedeemReducer";

const requestBalance = (data: any) => {
  return redeemApi.sendRequestAmount(data);
};

function* sendRequestAmount(ph: any): Generator<any> {
  try {
    const response: any = yield call(requestBalance, ph.payload);
    toast.success(`${response?.message}`, {
      position: toast.POSITION.TOP_CENTER,
    });
    yield put(
      // LoginUserOtpSuccess({

      redeemSuccess({
        data: response,
      })
    );
  } catch (error: any) {
    toast.error(`${error?.message}`, {
      position: toast.POSITION.TOP_CENTER,
    });
    yield put(
      // LoginUserOtpFailure({
      redeemFailed({
        error: error?.message,
      })
    );
  }
}

function* redeemSaga() {
  yield all([takeLatest(redeemRequest, sendRequestAmount)]);
}

export default redeemSaga;
