import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

export interface redeem {
  loading: boolean;
  data: any;
  error: string | null;
}

const initialState: redeem = {
  loading: false,
  data: null,
  error: null,
};

export const RedeemReducer: any = createSlice({
  name: "redeem",
  initialState,
  reducers: {
    redeemRequest(state: any, action: PayloadAction<any>): void {
      state.loading = true;
    },
    redeemSuccess(state: any, action: PayloadAction) {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
      // toast.success(`${action?.payload?.messsage}`, {
      //   position: toast.POSITION.TOP_CENTER,
      // });
    },
    redeemFailed(state: any, action: PayloadAction) {
      state.loading = false;
      state.data = action.payload;
    },

    logout(state: any) {
      state.isAuthenticated = false;
      state.loading = false;
      state.data = null;
    },
  },
});

export const { redeemRequest, redeemSuccess, redeemFailed, logout } =
  RedeemReducer.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value
// export const loginState = (state: RootState) => state.transaction
export default RedeemReducer.reducer;
