import axiosClient from "./axiosClient";

export const faqApi = {
  sendFaq(): any {
    const url = "/api/customer/faq";
    return axiosClient.post(url);
  },
};

export const cmsApi = {
  sendCms(params: any): any {
    const url = "/api/customer/cms-page";
    return axiosClient.post(url);
  },
};
