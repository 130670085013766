import axiosClient from "./axiosClient";

const globalSettingApi = {
  getGlobalSetting(): any {
    const url = "/api/global-setting";
    return axiosClient.post(url);
  },
};

export default globalSettingApi;
