// redux/user/actions.js
import { 
  LOGIN_OTP_REQUEST,
  LOGIN_OTP_SUCCESS,
  LOGIN_OTP_FAILURE,

  USER_OTP_VERIFY_REQUEST,
  USER_OTP_VERIFY_FAILURE,
  USER_OTP_VERIFY_SUCCESS,
  USER_LOGOUT,
} from '../../constants/Types';

import {
  LoginOtpRequest,
  LoginOtpSuccess,
  LoginOtpSuccessPayload,
  LoginOtpFailure,
  LoginOtpFailurePayload,
  
  OtpVerifyRequest,
  OtpVerifySuccess,
  OtpVerifySuccessPayload,
  OtpVerifyFailure,
  OtpVerifyFailurePayload
} from '../../constants/model'

export const LoginUserOtpRequest = (phone: any): LoginOtpRequest => ({
  type: LOGIN_OTP_REQUEST,
  payload: phone,

});

export const LoginUserOtpSuccess = (
  payload: LoginOtpSuccessPayload
): LoginOtpSuccess => ({
  type: LOGIN_OTP_SUCCESS,
  payload,
});

export const LoginUserOtpFailure = (
  payload: LoginOtpFailurePayload
): LoginOtpFailure => ({
  type: LOGIN_OTP_FAILURE,
  payload,
});

export const UserOtpVerifyRequest = (otp:any): OtpVerifyRequest => ({
  type: USER_OTP_VERIFY_REQUEST,
  payload: otp,

});

export const UserOtpVerifySuccess = (
  payload: OtpVerifySuccessPayload
): OtpVerifySuccess => ({
  type: USER_OTP_VERIFY_SUCCESS,
  payload,
});

export const UserOtpVerifyFailure = (
  payload: OtpVerifyFailurePayload
): OtpVerifyFailure => ({
  type: USER_OTP_VERIFY_FAILURE,
  payload,
});

export const UserLogout = (
  payload: any
  ): any => ({
    type: USER_LOGOUT,
    payload,
  });