import {
  LOGIN_OTP_REQUEST,
  USER_OTP_VERIFY_REQUEST,
} from "../../constants/Types";
import loginAPI from "../../API/loginAPI";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  loginRequest,
  logout,
  logOutFailed,
  logOutRequest,
  logOutSuccess,
  otpVerifyRequest,
} from "../reducers/LoginReducer";
import {
  LoginUserOtpSuccess,
  LoginUserOtpFailure,
  UserOtpVerifySuccess,
  UserOtpVerifyFailure,
  UserLogout,
} from "../../Redux - Saga/actions/LoginAction";
import {
  LoginActions,
  LoginState,
  OtpVerifyState,
  OtpVerifyActions,
  LoginOtpSuccessPayload,
  LoginOtpFailurePayload,
} from "../../constants/model";
import {
  loginSuccess,
  loginFailed,
  otpVerifySuccess,
  otpVerifyFailed,
} from "../reducers/LoginReducer";
import { toast } from "react-toastify";
const fetchUserOTP = (mobile: any) => {
  return loginAPI.getOTP(mobile);
};

function* getOtpSaga(ph: any): Generator<any> {
  try {
    const response: any = yield call(fetchUserOTP, ph.payload);
    yield put(
      // LoginUserOtpSuccess({

      loginSuccess({
        data: response,
      })
    );
  } catch (error: any) {
    toast.error(`${error?.message}`, {
      position: toast.POSITION.TOP_CENTER,
    });
    yield put(
      // LoginUserOtpFailure({
      loginFailed({
        error: error?.message,
      })
    );
  }
}

const verifyUserOTP = async (otp: any) => {
  return await loginAPI.verifyOTP({ otp });
};

function* verifyOtpSaga(otp: any): Generator<any> {
  try {
    const response: any = yield call(verifyUserOTP, otp.payload);
    if (response.token) {
      localStorage.setItem("user", JSON.stringify(response.token));
      yield put(
        // UserOtpVerifySuccess({
        otpVerifySuccess({
          data: response,
        })
      );
    } else {
      yield put(
        // UserOtpVerifyFailure({
        otpVerifyFailed({
          data: response,
        })
      );
    }
  } catch (error: any) {
    toast.error(`${error?.message}`, {
      position: toast.POSITION.TOP_CENTER,
    });
    yield put(
      UserOtpVerifyFailure({
        error: error?.message,
      })
    );
  }
}

// function* userLogout(): Generator<any> {
//   yield put(
//     userLogout({

//     })
//   )
// }
const logOutUser = async () => {
  return await loginAPI.logOutUser();
};

function* logOut(): Generator<any> {
  try {
    const response: any = yield call(logOutUser);
    yield put(
      // LoginUserOtpSuccess({

      logOutSuccess({
        data: response,
      })
    );
  } catch (error: any) {
    toast.error(`${error?.message}`, {
      position: toast.POSITION.TOP_CENTER,
    });
    yield put(
      // LoginUserOtpFailure({
      logOutFailed({
        error: error?.message,
      })
    );
  }
}

function* loginSaga() {
  yield all([takeLatest(loginRequest, getOtpSaga)]);
  yield all([takeLatest(otpVerifyRequest, verifyOtpSaga)]);
  yield all([takeLatest(logOutRequest, logOut)]);
}

export default loginSaga;
