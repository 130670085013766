import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

const axiosClient = axios.create({
  baseURL: `https://loyalty-program.katdev.com/`,
  headers: {
    "Content-Type": "application/json",
  },
});

// Interceptors
axiosClient.interceptors.request.use(
  function (req: any) {
    // Do something before request is sent

    const token = JSON.parse(localStorage.getItem("user") || "{}");
    // const token = data?.token;
    if (token) {
      req.headers.Authorization = `Bearer ${token}`;
    }

    return req;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosClient.interceptors.response.use(
  function (response: AxiosResponse) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response.data;
  },
  function (error) {
    const originalConfig = error.config;
    if (originalConfig.url !== "/" && error.response) {
      // Access Token was expired
      if (error.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        if (error.response.status === 401) {
          // localStorage.removeItem("accessToken");
          localStorage.clear();

          if (typeof window !== "undefined") {
            setTimeout(function () {
              window.location.href = "/";
            }, 2600);
          }
          // Promise.reject({
          //   message: "Session expired, please login again.",
          // });

          window.location.reload();

          return Promise.reject({
            message: "Session expired, please login again.",
          });
        }
      }
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error);
    }
  }
);

export default axiosClient;

// API.interceptors.request.use((req: any) => {
//     if (localStorage.getItem("token")) {

//       req.headers.token = `${localStorage.getItem("token") || ""}`;
//     }
//     return req;
//   });
