export const LOGIN_OTP_REQUEST = 'LOGIN_OTP_REQUEST';
export const LOGIN_OTP_STARTED = 'LOGIN_OTP_STARTED';
export const LOGIN_OTP_SUCCESS = 'LOGIN_OTP_SUCCESS';
export const LOGIN_OTP_FAILURE = 'LOGIN_OTP_FAILURE';

export const USER_OTP_VERIFY_REQUEST = 'USER_OTP_VERIFY_REQUEST';
export const USER_OTP_VERIFY_STARTED = 'USER_OTP_VERIFY_STARTED';
export const USER_OTP_VERIFY_SUCCESS = 'USER_OTP_VERIFY_SUCCESS';
export const USER_OTP_VERIFY_FAILURE = 'USER_OTP_VERIFY_FAILURE';

export const GET_RECENT_TRANSACTION_REQUEST = 'GET_RECENT_TRANSACTION_REQUEST';
export const GET_RECENT_TRANSACTION_STARTED = 'GET_RECENT_TRANSACTION_STARTED';
export const GET_RECENT_TRANSACTION_SUCCESS = 'GET_RECENT_TRANSACTION_SUCCESS';
export const GET_RECENT_TRANSACTION_FAILURE = 'GET_RECENT_TRANSACTION_FAILURE';

export const GET_TRANSACTION_HISTORY_REQUEST = 'GET_TRANSACTION_HISTORY_REQUEST';
export const GET_TRANSACTION_HISTORY_STARTED = 'GET_TRANSACTION_HISTORY_STARTED';
export const GET_TRANSACTION_HISTORY_SUCCESS = 'GET_TRANSACTION_HISTORY_SUCCESS';
export const GET_TRANSACTION_HISTORY_FAILURE = 'GET_TRANSACTION_HISTORY_FAILURE';

export const USER_LOGOUT = "USER_LOGOUT";
