import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface redeem {
  loading: boolean;
  data: any;
  error: string | null;
}

const initialState: redeem = {
  loading: false,
  data: null,
  error: null,
};

export const FaqReducer: any = createSlice({
  name: "faq",
  initialState,
  reducers: {
    faqRequest(state: any, action: PayloadAction<any>): void {
      state.loading = true;
    },
    faqSuccess(state: any, action: PayloadAction) {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    },
    faqFailed(state: any, action: PayloadAction) {
      state.loading = false;
      state.data = action.payload;
    },

    cmsRequest(state: any, action: PayloadAction<any>): void {
      state.loading = true;
    },
    cmsSuccess(state: any, action: PayloadAction) {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    },
    cmsFailed(state: any, action: PayloadAction) {
      state.loading = false;
      state.data = action.payload;
    },

    logout(state: any) {
      state.isAuthenticated = false;
      state.loading = false;
      state.data = null;
    },
  },
});

export const {
  faqRequest,
  faqSuccess,
  faqFailed,
  logout,
  cmsSuccess,
  cmsFailed,
  cmsRequest,
} = FaqReducer.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value
// export const loginState = (state: RootState) => state.transaction
export default FaqReducer.reducer;
