import axiosClient from "./axiosClient";

const loginApi = {
  getOTP(params: any): any {
    const url = "/api/customer/login";
    return axiosClient
      .post(url, {
        phone: params,
      })
      .catch((err) => {
        console.log("-err-", err);
      });
  },

  verifyOTP(params: any): any {
    const url = "/api/customer/login";
    return axiosClient
      .post(url, {
        phone: params.otp.Mobile,
        otp: params.otp.Otp,
      })
      .catch((err) => {
        console.log("-err-", err);
      });
  },
  logOutUser(): any {
    const url = "/api/customer/logout";
    return axiosClient.post(url).catch((err) => {
      console.log("-err-", err);
    });
  },
};

export default loginApi;
