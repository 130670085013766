import { toast } from "react-toastify";
import { all, call, put, takeLatest } from "redux-saga/effects";
import searchApi from "../../API/searchApi";
import {
  paginationFailed,
  paginationRequest,
  paginationSuccess,
  searchFailed,
  searchRequest,
  searchSuccess,
} from "../reducers/SearchReducer";

const sendData = (data: any) => {
  return searchApi.sendRequest(data);
};

function* sendSearchData(ph: any): Generator<any> {
  try {
    const response: any = yield call(sendData, ph.payload);
    yield put(
      // LoginUserOtpSuccess({

      searchSuccess({
        data: response,
      })
    );
  } catch (error: any) {
    toast.error(`${error?.message}`, {
      position: toast.POSITION.TOP_CENTER,
    });
    yield put(
      // LoginUserOtpFailure({
      searchFailed({
        error: error?.message,
      })
    );
  }
}

const sendPageData = (data: any) => {
  return searchApi.sendPaginationRequest(data);
};

function* sendPaginationData(ph: any): Generator<any> {
  try {
    const response: any = yield call(sendPageData, ph.payload);
    if (response.data) {
      yield put(
        // LoginUserOtpSuccess({

        paginationSuccess({
          data: response,
        })
      );
    } else {
      yield put(
        // LoginUserOtpFailure({
        paginationFailed({
          error: response?.message,
        })
      );
    }
  } catch (error: any) {
    toast.error(`${error?.message}`, {
      position: toast.POSITION.TOP_CENTER,
    });
    yield put(
      // LoginUserOtpFailure({
      paginationFailed({
        error: error?.message,
      })
    );
  }
}

function* searchSaga() {
  yield all([takeLatest(searchRequest, sendSearchData)]);
  yield all([takeLatest(paginationRequest, sendPaginationData)]);
}

export default searchSaga;
