import { all, call, put, takeLatest } from "redux-saga/effects";
import { cmsApi, faqApi } from "../../API/faqApi";
import { toast } from "react-toastify";
import {
  faqRequest,
  faqSuccess,
  faqFailed,
  cmsRequest,
  cmsSuccess,
  cmsFailed,
} from "../reducers/FaqReducer";

const requestBalance = () => {
  return faqApi.sendFaq();
};

function* sendFaq(ph: any): Generator<any> {
  try {
    const response: any = yield call(requestBalance);
    yield put(
      // LoginUserOtpSuccess({

      faqSuccess({
        data: response,
      })
    );
    // toast.success("Successful !", {
    //   position: toast.POSITION.TOP_CENTER,
    // });
  } catch (error: any) {
    yield put(
      // LoginUserOtpFailure({
      faqFailed({
        error: error?.message,
      })
    );
  }
}

const cmsDetails = (data: any) => {
  return cmsApi.sendCms(data);
};

function* sendCms(ph: any): Generator<any> {
  try {
    const response: any = yield call(cmsDetails, ph.payload);
    yield put(
      // LoginUserOtpSuccess({

      cmsSuccess({
        data: response,
      })
    );
    // toast.success("Successful !", {
    //   position: toast.POSITION.TOP_CENTER,
    // });
  } catch (error: any) {
    yield put(
      // LoginUserOtpFailure({
      cmsFailed({
        error: error?.message,
      })
    );
  }
}

function* faqSaga() {
  yield all([takeLatest(faqRequest, sendFaq)]);
  yield all([takeLatest(cmsRequest, sendCms)]);
}

export default faqSaga;
